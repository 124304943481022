import React from 'react'
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  ListSubheader,
  Checkbox,
} from '@material-ui/core'

export default function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    style,
    variant,
    children,
    labelStyle,
    multiple,
    manualSelectedItems,
    renderValue,
    ...other
  } = props

  return (
    <FormControl
      variant={variant || 'outlined'}
      {...(error && { error: true })}
      style={style}
    >
      <InputLabel style={labelStyle}>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        multiple={multiple}
        renderValue={renderValue}
        {...other}
      >
        {children}
        {[].concat(
          ...options.map((item) =>
            item.children !== undefined
              ? [
                  <ListSubheader style={{ lineHeight: 2, fontSize: 'medium' }}>
                    {item.title}
                  </ListSubheader>,
                ].concat(
                  ...item.children.map((child) => [
                    <MenuItem
                      key={child.id}
                      value={child.id}
                      style={{
                        marginLeft: '1rem',
                        background: manualSelectedItems.includes(child.id)
                          ? '#ebebeb'
                          : undefined,
                      }}
                    >
                      <Checkbox
                        checked={
                          manualSelectedItems.length > 0 &&
                          manualSelectedItems.includes(child.id)
                        }
                      />
                      {child.title}
                    </MenuItem>,
                  ]),
                )
              : [
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>,
                ],
          ),
        )}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
